import { type Prisma, type RankChangeType, type ReportRowType } from '@prisma/client';
import { type MessageDescriptor, defineMessage } from 'react-intl';

export type FetchState =
    | {
          type: 'idle';
      }
    | {
          type: 'loading';
      }
    | {
          type: 'error';
      };

export type ReportType = ReportRowType;

export type RankChange =
    | {
          type: 'noChange';
      }
    | { type: 'debut' }
    | { type: 'return' }
    | { type: 'up'; change: number }
    | { type: 'down'; change: number };

export function getRankChange(type: RankChangeType, change: number): RankChange {
    switch (type) {
        case 'debut':
        case 'noChange':
        case 'return': {
            if (change !== 0) {
                throw Error(`rank change ${type} but change !== 0 (${change})`);
            }

            return {
                type,
            };
        }

        case 'down':
        case 'up': {
            return {
                type,
                change,
            };
        }
    }
}

export type ReportRssMetadata = {
    category: string | null;
    description: string | null;
    imageUrl: string;
    title: string;
};

export type ReportImageMetadata = {
    imageDominantColor: string;
};

// the generated Prisma input types give us mostly what we want, but we want to clean out some defaults:
// - category is part of metadata so we don't pass it directly. omit that.
// - separate rank columns should be combined into a single RankChange
type PrismaTypeFields<T> = Omit<T, 'category' | 'rankChangeType' | 'rankChangeValue'> &
    (T extends {
        rankChangeType: RankChangeType;
        rankChangeValue: number;
    }
        ? { rankChange: RankChange }
        : never);

// we also want to strip out the "create" objects from the Prisma input types. we should work with simpler IDs instead.
type PrismaType<T extends Record<string, unknown>> = PrismaTypeFields<{
    [Key in keyof T as T[Key] extends { create?: unknown } | undefined ? never : Key]: T[Key];
}>;

// row definitions. this is a listing of each type of row and the data required to create one of those rows:
type NetworkAuRows = {
    type: 'networkAu';
    rows: (PrismaType<Prisma.ReportNetworkAuRowCreateWithoutReportInput> & {
        publishers: string[];
        salesRepresentatives: string[];
    })[];
};

type NetworkCaRows = {
    type: 'networkCa';
    rows: (PrismaType<Prisma.ReportNetworkCaRowCreateWithoutReportInput> & {
        networks: string[];
        salesRepresentatives: string[];
    })[];
};

type NetworkLatamRows = {
    type: 'networkLatam';
    rows: (PrismaType<Prisma.ReportNetworkLatamRowCreateWithoutReportInput> & {
        networks: string[];
        salesRepresentatives: string[];
    })[];
};

type NetworkNlRows = {
    type: 'networkNl';
    rows: (PrismaType<Prisma.ReportNetworkNlRowCreateWithoutReportInput> & {
        networks: string[];
        salesRepresentatives: string[];
    })[];
};

type NetworkNzRows = {
    type: 'networkNz';
    rows: (PrismaType<Prisma.ReportNetworkNzRowCreateWithoutReportInput> & {
        networks: string[];
        salesRepresentatives: string[];
    })[];
};

type NetworkUsDownloadsRows = {
    type: 'networkUsDownloads';
    rows: (PrismaType<Prisma.ReportNetworkUsDownloadsRowCreateWithoutReportInput> & {
        networks: string[];
        salesRepresentatives: string[];
    })[];
};

type NetworkUsUsersRows = {
    type: 'networkUsUsers';
    rows: (PrismaType<Prisma.ReportNetworkUsUsersRowCreateWithoutReportInput> & {
        networks: string[];
        salesRepresentatives: string[];
    })[];
};

type PodcastAuRows = {
    type: 'podcastAu';
    rows: (PrismaType<Prisma.ReportPodcastAuRowCreateWithoutReportInput> & {
        podcastName: string;
        publishers: string[];
        salesRepresentatives: string[];
        stationId: number;
    })[];
};

type PodcastLatamRows = {
    type: 'podcastLatam';
    rows: (PrismaType<Prisma.ReportPodcastLatamRowCreateWithoutReportInput> & {
        podcastName: string;
        publishers: string[];
        stationId: number;
    })[];
};

type PodcastNlRows = {
    type: 'podcastNl';
    rows: (PrismaType<Prisma.ReportPodcastNlRowCreateWithoutReportInput> & {
        networks: string[];
        podcastName: string;
        publishers: string[];
        stationId: number;
    })[];
};

type PodcastNzRows = {
    type: 'podcastNz';
    rows: (PrismaType<Prisma.ReportPodcastNzRowCreateWithoutReportInput> & {
        networks: string[];
        podcastName: string;
        salesRepresentatives: string[];
        stationId: number;
    })[];
};

type PodcastUsRows = {
    type: 'podcastUs';
    rows: (PrismaType<Prisma.ReportPodcastUsRowCreateWithoutReportInput> & {
        networks: string[];
        podcastName: string;
        salesRepresentatives: string[];
        stationId: number;
    })[];
};

type SalesRepresentativeAuRows = {
    type: 'salesRepresentativeAu';
    rows: (PrismaType<Prisma.ReportSalesRepresentativeAuRowCreateWithoutReportInput> & {
        publishers: string[];
        salesRepresentatives: string[];
    })[];
};

export type DemoPlusPodcastCharacteristicHeader = (typeof allDemosPlusPodcastAuHeaders)[number];
export type DemosPlusCsvCharacteristic = Partial<Record<DemoPlusPodcastCharacteristicHeader, number>>;
type DemosPlusPodcastAuRows = {
    type: 'demosPlusPodcastAu';
    rows: (PrismaType<Prisma.ReportDemosPlusPodcastAuRowCreateWithoutReportInput> & {
        characteristics: DemosPlusCsvCharacteristic;
        category: string;
    })[];
};

// some of the rows above are simple data
type RowCsv<Type extends string, Row> = {
    type: Type;
    rowType: 'none';
    rows: Row[];
};

// convert the rows listed above to a RowCsv without having to manage the generics manually
type RowCsvExtractor<T extends { type: string; rows: unknown[] }> = RowCsv<T['type'], T['rows'][number]>;

// other rows have RSS metadata. we want to organise the collection of that metadata.
export type RssState =
    | {
          type: 'noRssUrls';
      }
    | {
          type: 'multipleRssUrls';
          rssUrls: string[];
          fetchState: FetchState;
      }
    | {
          type: 'noRssMetadata';
          rssUrl: string;
          fetchState: FetchState;
      }
    | {
          type: 'noImageMetadata';
          rssMetadata: ReportRssMetadata;
          fetchState: FetchState;
      }
    | {
          type: 'allMetadata';
          rssMetadata: ReportRssMetadata;
          imageMetadata: ReportImageMetadata;
      };

// each row has RSS data attached
export type RowCsvRss<Type extends string, Row> = {
    type: Type;
    rowType: 'rss';
    rows: {
        data: Row;
        rssProvider: string;
        rssState: RssState;
    }[];
};

// convert the rows listed above to a RowCsvRss without having to manage the generics manually
type RowCsvRssExtractor<T extends { type: string; rows: unknown[] }> = RowCsvRss<T['type'], T['rows'][number]>;

// to create a row that depends on metadata, we need both the RSS and image metadata
export type RowRssCreate<Type extends string, Row> = {
    type: Type;
    rows: {
        data: Row;
        rssMetadata: ReportRssMetadata;
        imageMetadata: ReportImageMetadata;
    }[];
};

// if all of the rows have their metadata, we're ready to send
export function validateRss<Type extends string, Row>(
    input: RowCsvRss<Type, Row>,
): { ok: true; payload: RowRssCreate<Type, Row> } | { ok: false } {
    const payload: RowRssCreate<Type, Row> = {
        type: input.type,
        rows: [],
    };

    for (const row of input.rows) {
        if (row.rssState.type !== 'allMetadata') {
            return {
                ok: false,
            };
        }

        payload.rows.push({
            data: row.data,
            rssMetadata: row.rssState.rssMetadata,
            imageMetadata: row.rssState.imageMetadata,
        });
    }

    return {
        ok: true,
        payload,
    };
}

// convert the rows listed above to a RowRssCreate without having to manage the generics manually
type RowRssCreateExtractor<T extends { type: string; rows: unknown[] }> = RowRssCreate<T['type'], T['rows'][number]>;

// csv definitions (how should each type of CSV be represented? does it have metadata or not?)

export type NetworkAuCsvRows = RowCsvExtractor<NetworkAuRows>;

export type NetworkLatamCsvRows = RowCsvExtractor<NetworkLatamRows>;

export type NetworkCaCsvRows = RowCsvExtractor<NetworkCaRows>;

export type NetworkNlCsvRows = RowCsvExtractor<NetworkNlRows>;

export type NetworkNzCsvRows = RowCsvExtractor<NetworkNzRows>;

export type NetworkUsDownloadsCsvRows = RowCsvExtractor<NetworkUsDownloadsRows>;

export type NetworkUsUsersCsvRows = RowCsvExtractor<NetworkUsUsersRows>;

export type PodcastAuCsvRows = RowCsvRssExtractor<PodcastAuRows>;

export type PodcastLatamCsvRows = RowCsvRssExtractor<PodcastLatamRows>;

export type PodcastNlCsvRows = RowCsvRssExtractor<PodcastNlRows>;

export type PodcastNzCsvRows = RowCsvRssExtractor<PodcastNzRows>;

export type PodcastUsCsvRows = RowCsvRssExtractor<PodcastUsRows>;

export type SalesRepresentativeAuCsvRows = RowCsvExtractor<SalesRepresentativeAuRows>;

export type DemosPlusPodcastAuCsvRows = RowCsvExtractor<DemosPlusPodcastAuRows>;

export type AllCsvRows =
    | NetworkAuCsvRows
    | NetworkCaCsvRows
    | NetworkNlCsvRows
    | NetworkNzCsvRows
    | NetworkUsDownloadsCsvRows
    | NetworkUsUsersCsvRows
    | PodcastAuCsvRows
    | PodcastLatamCsvRows
    | PodcastNlCsvRows
    | PodcastNzCsvRows
    | PodcastUsCsvRows
    | SalesRepresentativeAuCsvRows
    | NetworkLatamCsvRows
    | DemosPlusPodcastAuCsvRows;

// create definitions (what data do we need on hand to save rows to the database?)

export type NetworkAuCreateRows = NetworkAuRows;

export type NetworkCaCreateRows = NetworkCaCsvRows;

export type NetworkLatamCreateRows = NetworkLatamCsvRows;

export type NetworkNlCreateRows = NetworkNlCsvRows;

export type NetworkNzCreateRows = NetworkNzCsvRows;

export type NetworkUsDownloadsCreateRows = NetworkUsDownloadsCsvRows;

export type NetworkUsUsersCreateRows = NetworkUsUsersCsvRows;

export type PodcastAuCreateRows = RowRssCreateExtractor<PodcastAuRows>;

export type PodcastLatamCreateCsvRows = RowRssCreateExtractor<PodcastLatamRows>;

export type PodcastNlCreateCsvRows = RowRssCreateExtractor<PodcastNlRows>;

export type PodcastNzCreateCsvRows = RowRssCreateExtractor<PodcastNzRows>;

export type PodcastUsCreateCsvRows = RowRssCreateExtractor<PodcastUsRows>;

export type SalesRepresentativeAuCreateRows = SalesRepresentativeAuCsvRows;

export type DemosPlusPodcastAuCreateRows = DemosPlusPodcastAuCsvRows;

export type AllCreateRows =
    | NetworkAuCreateRows
    | NetworkLatamCreateRows
    | NetworkCaCreateRows
    | NetworkNlCreateRows
    | NetworkNzCreateRows
    | NetworkUsDownloadsCreateRows
    | NetworkUsUsersCreateRows
    | PodcastAuCreateRows
    | PodcastLatamCreateCsvRows
    | PodcastNlCreateCsvRows
    | PodcastNzCreateCsvRows
    | PodcastUsCreateCsvRows
    | SalesRepresentativeAuCreateRows
    | DemosPlusPodcastAuCreateRows;

// TODO: Validate those headers for the first demo+ report
// All new demo plus podcast AU headers should be added here
export const allDemosPlusPodcastAuHeaders = [
    'Adults 18-24',
    'Adults 25-39',
    'Adults 25-54',
    'Female 25-54',
    'Male 25-54',
    'Parent with child (<18)',
    'Podcasts < weekly',
    'Radio streamed - past month',
    'YouTube - past week',
    'TV catch-up - past week',
] as const;

type HeaderMessageMap = {
    [key in (typeof allDemosPlusPodcastAuHeaders)[number]]: MessageDescriptor;
};

export const allDemosPlusHeaderMessageMap: HeaderMessageMap = {
    'Adults 18-24': defineMessage({
        id: 'demosPlusPodcastAuHeaders.adults1824',
        defaultMessage: 'Adults 18-24',
    }),
    'Adults 25-39': defineMessage({
        id: 'demosPlusPodcastAuHeaders.adults2539',
        defaultMessage: 'Adults 25-39',
    }),
    'Adults 25-54': defineMessage({
        id: 'demosPlusPodcastAuHeaders.adults2554',
        defaultMessage: 'Adults 25-54',
    }),
    'Female 25-54': defineMessage({
        id: 'demosPlusPodcastAuHeaders.female2554',
        defaultMessage: 'Female 25-54',
    }),
    'Male 25-54': defineMessage({
        id: 'demosPlusPodcastAuHeaders.male2554',
        defaultMessage: 'Male 25-54',
    }),
    'Parent with child (<18)': defineMessage({
        id: 'demosPlusPodcastAuHeaders.parentWithChild',
        defaultMessage: 'Parent with child (<18)',
    }),
    'Podcasts < weekly': defineMessage({
        id: 'demosPlusPodcastAuHeaders.podcastsWeekly',
        defaultMessage: 'Podcasts < weekly',
    }),
    'Radio streamed - past month': defineMessage({
        id: 'demosPlusPodcastAuHeaders.radioStreamedPastMonth',
        defaultMessage: 'Radio streamed - past month',
    }),
    'YouTube - past week': defineMessage({
        id: 'demosPlusPodcastAuHeaders.youTubePastWeek',
        defaultMessage: 'YouTube - past week',
    }),
    'TV catch-up - past week': defineMessage({
        id: 'demosPlusPodcastAuHeaders.tvCatchUpPastWeek',
        defaultMessage: 'TV catch-up - past week',
    }),
};
