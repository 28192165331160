import { createIntl } from '@formatjs/intl';
import { InfoOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SubjectIcon from '@mui/icons-material/Subject';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRouter } from 'next/router';
import * as React from 'react';
import { FormattedDate, FormattedMessage, useIntl, type IntlShape, type MessageDescriptor } from 'react-intl';
import { Flag } from './Flag';
import { useLocale } from 'components/LocalizationProvider';
import {
    ReportOverlay,
    ReportOverlayGrid,
    ReportOverlayGridActivePodcasts,
    ReportOverlayGridAverageWeeklyDownloads,
    ReportOverlayGridAverageWeeklyUsers,
    ReportOverlayGridMonthly,
    ReportOverlayGridNetwork,
    ReportOverlayGridNewEpisodes,
    ReportOverlayGridPublishersRepresented,
    ReportOverlayGridRank,
    ReportOverlayGridSalesRepresentative,
    ReportOverlayPodcast,
    ReportOverlayText,
    SimpleGridOverlay,
} from 'components/ReportOverlay';
import { getDateFromDateString } from 'helpers/date';
import { localeCompare } from 'helpers/locale';
import { getRankerPdfUrl, getRankerUrl } from 'helpers/urls';
import { useRankerJsonLoader } from 'hooks/useRankerJsonLoader';
import { type Locale, messagesByLocale } from 'models/locale';
import { type ReportSet, getReportSetTitle, regionTitles, regionIds, regionLabels, getReportType } from 'models/report';
import { type RankerApi } from 'pages/api/ranker';
import {
    type ReportNetworkLatam,
    type ReportNetworkAu,
    type ReportNetworkCa,
    type ReportNetworkNl,
    type ReportNetworkNz,
    type ReportNetworkUsDownloads,
    type ReportNetworkUsUsers,
    type ReportPodcastAu,
    type ReportPodcastLatam,
    type ReportPodcastNl,
    type ReportPodcastNz,
    type ReportPodcastUs,
    type ReportSalesRepresentativeAu,
    type ReportDemosPlusPodcastAu,
} from 'report/getReport';
import { type ReportPeriod, formatReportPeriodLabel } from 'report/reportPeriod';
import { type DemoPlusPodcastCharacteristicHeader, type ReportType } from 'report/reportTypes';
import {
    type TableColumn,
    type TableColumnPdf,
    type TableColumnPdfCell,
    activePodcastsTableColumn,
    averageWeeklyDownloadsTableColumn,
    averageWeeklyUsersTableColumn,
    monthlyDownloadsTableColumn,
    monthlyListenersTableColumn,
    networkTableColumn,
    podcastNameTableColumn,
    podcastNameWithPublisherTableColumn,
    publishersTableColumn,
    publisherTableColumn,
    rankTableColumn,
    salesNetworkTableColumn,
    salesRepresentativeTableColumn,
    rankChangeTableColumn,
    newEpisodesTableColumn,
    categoryTableColumnPdf,
    getDemosPlusCharacteristicColumns,
    getDemosPlusPdfCharacteristicColumns,
    categoryTableColumn,
    getDemoPlusColumnHeader,
} from 'report/tableColumns';
import {
    type TableFilter,
    categoryFilterDefinition,
    networkFilterDefinition,
    publisherFilterDefinition,
    salesNetworkFilterDefinition,
    salesRepresentativeFilterDefinition,
    demosPlusCharacteristicColumnSelector,
} from 'report/tableFilters';
import { theme } from 'styles/mui-theme';
import {
    ReportFormControl,
    ReportSelect,
    TritonTooltip,
    TritonTable,
    tritonTableRecordRow,
    tritonTableRecordRowAlternate,
    ReportSelectSecondary,
    ReportDownloadButton,
    ReportInputLabel,
    TritonDivider,
} from 'styles/styles';

export type ReportTablePdfData = {
    headers: string[];
    rows: TableColumnPdfCell[][];
};

export const ReportTableNetworkAu = createReportTablePartial<ReportNetworkAu>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [publisherTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [publisherTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.publishers.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.publishers.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkCa = createReportTablePartial<ReportNetworkCa>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

// TODO: Remove comments when we want to display salesRep Column
export const ReportTableNetworkLatam = createReportTablePartial<ReportNetworkLatam>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        // [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        // [salesRepresentativeTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            {/* <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid> */}
        </>
    ),
});

export const ReportTableNetworkNl = createReportTablePartial<ReportNetworkNl>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [networkTableColumn, { width: undefined }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [averageWeeklyUsersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [networkTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [averageWeeklyUsersTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridAverageWeeklyUsers row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkNz = createReportTablePartial<ReportNetworkNz>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [networkTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [networkTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkUsDownloads = createReportTablePartial<ReportNetworkUsDownloads>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [averageWeeklyDownloadsTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableNetworkUsUsers = createReportTablePartial<ReportNetworkUsUsers>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesNetworkTableColumn, { width: undefined }],
        [averageWeeklyUsersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [activePodcastsTableColumn, { hideWhenNarrow: true, width: 155 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [averageWeeklyUsersTableColumn],
        [activePodcastsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.networks.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyUsers row={row} />
                <ReportOverlayGridActivePodcasts row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.networks.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastAu = createReportTablePartial<ReportPodcastAu>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameWithPublisherTableColumn, { width: undefined }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [publisherTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, publisherFilterDefinition, salesRepresentativeFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="publisher"
                company={row.publishers}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={onFilterApply}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastLatam = createReportTablePartial<ReportPodcastLatam>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [publisherTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 220 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [publisherTableColumn],
        [averageWeeklyDownloadsTableColumn],
    ],
    filters: [categoryFilterDefinition, publisherFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="publisher"
                company={row.publishers}
                onFilterApply={onFilterApply}
            />
        </>
    ),
});

export const ReportTablePodcastNl = createReportTablePartial<ReportPodcastNl>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [networkTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [averageWeeklyDownloadsTableColumn, { hideWhenNarrow: true, width: 200 }],
        [averageWeeklyUsersTableColumn, { hideWhenNarrow: true, width: 165 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [networkTableColumn],
        [categoryTableColumnPdf],
        [averageWeeklyDownloadsTableColumn],
        [averageWeeklyUsersTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, networkFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridAverageWeeklyDownloads row={row} />
                <ReportOverlayGridAverageWeeklyUsers row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey={null}
                company={row.publishers}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridNetwork
                    networks={row.networks}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={onFilterApply}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastNz = createReportTablePartial<ReportPodcastNz>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [networkTableColumn, { hideWhenNarrow: true, width: '22%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [networkTableColumn],
        [salesRepresentativeTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, networkFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="network"
                company={row.networks}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTablePodcastUs = createReportTablePartial<ReportPodcastUs>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [podcastNameTableColumn, { width: undefined }],
        [salesNetworkTableColumn, { hideWhenNarrow: true, width: '20%' }],
        [salesRepresentativeTableColumn, { hideWhenNarrow: true, width: '25%' }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [podcastNameTableColumn],
        [salesNetworkTableColumn],
        [salesRepresentativeTableColumn],
        [newEpisodesTableColumn],
    ],
    filters: [categoryFilterDefinition, salesNetworkFilterDefinition],
    getRowId: (row) => row.podcast.stationId.toString(),
    Overlay: ({ row, onFilterApply }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridNewEpisodes row={row} />
            </ReportOverlayGrid>
            <ReportOverlayPodcast
                row={row}
                companyFilterKey="salesNetwork"
                company={row.networks}
                onFilterApply={onFilterApply}
            />
            <ReportOverlayGrid>
                <ReportOverlayGridSalesRepresentative
                    row={row}
                    getCompanyUrl={(company) => company.salesUrl}
                    onFilterApply={null}
                />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableSalesRepresentativeAu = createReportTablePartial<ReportSalesRepresentativeAu>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [salesRepresentativeTableColumn, { width: undefined }],
        [publishersTableColumn, { hideWhenNarrow: true, width: '30%' }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
        [monthlyDownloadsTableColumn, { hideWhenNarrow: true, width: 180 }],
    ],
    pdfColumns: [
        [rankTableColumn],
        [rankChangeTableColumn],
        [salesRepresentativeTableColumn],
        [publishersTableColumn],
        [monthlyListenersTableColumn],
        [monthlyDownloadsTableColumn],
    ],
    filters: [],
    getRowId: (row) => row.salesRepresentatives.id,
    Overlay: ({ row }) => (
        <>
            <ReportOverlayGrid>
                <ReportOverlayGridRank row={row} />
                <ReportOverlayGridMonthly row={row} />
            </ReportOverlayGrid>
            <ReportOverlayText>{row.salesRepresentatives.name}</ReportOverlayText>
            <ReportOverlayGrid>
                <ReportOverlayGridPublishersRepresented row={row} getCompanyUrl={(company) => company.salesUrl} />
            </ReportOverlayGrid>
        </>
    ),
});

export const ReportTableDemosPlusPodcastAu = createReportTablePartial<ReportDemosPlusPodcastAu>()({
    columns: [
        [rankTableColumn, { width: 100 }],
        [categoryTableColumn, { width: 100 }],
        [monthlyListenersTableColumn, { hideWhenNarrow: true, width: 165 }],
    ],
    pdfColumns: [[rankTableColumn], [rankChangeTableColumn], [categoryTableColumnPdf], [monthlyListenersTableColumn]],
    filters: [],
    selector: demosPlusCharacteristicColumnSelector,
    getRowId: (row) => row.id,
    Overlay: ({ row }) => {
        const headers = Object.keys(row.characteristics).sort(localeCompare);

        return (
            <>
                <ReportOverlayGrid>
                    <ReportOverlayGridRank row={row} />
                    <ReportOverlayGridMonthly row={row} />
                </ReportOverlayGrid>
                <ReportOverlayText>{row.category}</ReportOverlayText>
                <ReportOverlayGrid>
                    {headers.map((header) => (
                        <SimpleGridOverlay
                            key={header}
                            name={header}
                            value={row.characteristics[header as keyof typeof row.characteristics]}
                        ></SimpleGridOverlay>
                    ))}
                </ReportOverlayGrid>
            </>
        );
    },
});

export const getReportPeriodId = (period: ReportPeriod): string => `${period.year}/${period.month}`;

// we store the filter state as an array (for the MUI components) and a set (for our filter functions)
type FilterState = {
    array: string[];
    set: Set<string>;
};

export type OnFilterApply<FilterKey extends string> = (filterKey: FilterKey, filterValues: string[]) => void;

type CreateReportTableConfig<Ranker extends RankerApi, FilterKey extends string> = {
    columns: [TableColumn<Ranker['rows'][number]>, { hideWhenNarrow?: true; width: number | string | undefined }][];
    pdfColumns: [TableColumnPdf<Ranker['rows'][number]>][];
    filters: TableFilter<Ranker['rows'][number], FilterKey>[];
    Overlay: React.ComponentType<{
        row: Ranker['rows'][number];
        onFilterApply: OnFilterApply<FilterKey> | null;
    }>;
    getRowId: (row: Ranker['rows'][number]) => string;
    selector?: {
        key: string;
        messageDescriptorHeader: MessageDescriptor;
        messageDescriptorMultipleValues: MessageDescriptor;
    };
};

export type ReportTableProps = {
    accessKey: string | null;
    reportSet: ReportSet;
    reportSetLinks: { reportSet: ReportSet; hasReportForCurrentPeriod: boolean }[];
    period: ReportPeriod;
    periodStartDateString: string;
    periodEndDateString: string;
    reportPeriods: ReportPeriod[];
};

function createReportTable<Ranker extends RankerApi, FilterKey extends string>({
    columns,
    pdfColumns,
    filters,
    Overlay,
    getRowId,
    selector,
}: CreateReportTableConfig<Ranker, FilterKey>) {
    type State = {
        // use "partial" as we technically can't prove all the keys in the type were provided
        filterOptions: Partial<Record<FilterKey, string[]>>;
        filterStates: Partial<Record<FilterKey, FilterState>>;
        sortColumnIndex: number | null;
        sortDirection: 'asc' | 'desc';
    };

    type ColomnSelectorState = {
        columnOptionString: string[];
        columnOptionObject: [
            TableColumn<Ranker['rows'][number]>,
            {
                hideWhenNarrow?: true | undefined;
                width: number | string | undefined;
            },
        ][];
        columnState: Set<string>;
        sortColumnIndex: number | null;
        sortDirection: 'asc' | 'desc';
    };

    const getFiltersState = (rows: Ranker['rows']) => {
        const result: Pick<State, 'filterOptions' | 'filterStates'> = {
            filterOptions: {},
            filterStates: {},
        };

        for (const filter of filters) {
            // sanity check the same filter hasn't been supplied twice, or a filter accidentally has the same key as another
            if (result.filterOptions[filter.filterKey] || result.filterStates[filter.filterKey]) {
                throw Error('duplicate filter state');
            }

            result.filterOptions[filter.filterKey] = Array.from(filter.getOptions(rows));

            result.filterStates[filter.filterKey] = {
                array: [],
                set: new Set(),
            };
        }

        return result;
    };

    const defaultFilterState: State = {
        sortColumnIndex: 0,
        sortDirection: 'asc',
        ...getFiltersState([]),
    };

    const getColumnOptionsData = (
        rows: Ranker['rows'],
        reportType: ReportType | null,
        isPdf?: boolean,
    ): {
        columnOptionString: string[];
        columnOptionObject: [
            TableColumn<Ranker['rows'][number]>,
            { hideWhenNarrow?: true; width: number | string | undefined },
        ][];
        columnOptionObjectPdf?: [TableColumnPdf<Ranker['rows'][number]>][];
    } => {
        if (rows.length === 0 || reportType !== 'demosPlusPodcastAu') {
            return { columnOptionString: [], columnOptionObject: [] };
        }

        const columnOptions = getDemoPlusColumnHeader(rows as ReportDemosPlusPodcastAu['rows']);

        if (isPdf) {
            const columnOptionsObjectPdf = columnOptions.map(
                (columnOption) =>
                    [getDemosPlusPdfCharacteristicColumns(columnOption as DemoPlusPodcastCharacteristicHeader)] as [
                        TableColumnPdf<Ranker['rows'][number]>,
                    ],
            );

            return {
                columnOptionString: columnOptions,
                columnOptionObject: [],
                columnOptionObjectPdf: columnOptionsObjectPdf,
            };
        }

        const columnOptionsObject = columnOptions.map(
            (columnOption) =>
                [
                    getDemosPlusCharacteristicColumns(columnOption as DemoPlusPodcastCharacteristicHeader),
                    { hideWhenNarrow: true, width: 'auto' },
                ] as [
                    TableColumn<Ranker['rows'][number]>,
                    { hideWhenNarrow?: true; width: number | string | undefined },
                ],
        );

        return { columnOptionString: columnOptions, columnOptionObject: columnOptionsObject };
    };

    const defaultColumnSelectorState: ColomnSelectorState = {
        ...getColumnOptionsData([], null),
        columnState: new Set(),
        sortColumnIndex: null,
        sortDirection: 'asc',
    };

    const ReportTable = ({
        accessKey,
        period,
        periodStartDateString,
        periodEndDateString,
        reportSet,
        reportSetLinks,
        reportPeriods,
    }: ReportTableProps) => {
        const router = useRouter();

        const isFilterDisabled = useMediaQuery(theme.breakpoints.down('sm'));
        const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
        // Includes the state of the current filter (sorting index, sorting direction, filter values)
        const [state, setState] = React.useState(defaultFilterState);
        // Includes the state of the column selector (sorting index, sorting direction, column values)
        const [columnSelectorState, setColumnSelectorState] = React.useState(defaultColumnSelectorState);
        const [isLoading, setLoading] = React.useState(true);

        const rankerJsonResult = useRankerJsonLoader<Ranker>({
            period,
            reportSet,
            accessKey,
        });

        const rows = React.useMemo(
            () => (rankerJsonResult.type === 'success' ? rankerJsonResult.report.rows : []),
            [rankerJsonResult],
        );

        // we hide rows using styles, to avoid adding/removing rows from the DOM while toggling the filters.
        // this means we can't rely on rowsFiltered.length to see how many rows have been filtered.
        // calculate a separate variable for that.
        const { rowsFiltered, rowsFilteredVisibleCount } = React.useMemo((): {
            rowsFiltered: {
                row: Ranker['rows'][number];
                // what is the index of this row, ignoring the hidden rows?
                visibleIndex: number;
                hidden: boolean;
            }[];
            rowsFilteredVisibleCount: number;
        } => {
            if (isFilterDisabled) {
                return {
                    rowsFiltered: rows.map((row, index) => ({ row, visibleIndex: index, hidden: false })),
                    rowsFilteredVisibleCount: rows.length,
                };
            }

            let rowsFilteredVisibleCount = 0;

            const rowsFiltered = rows.map((row) => {
                let hidden = false;

                for (const filter of filters) {
                    const filterState = state.filterStates[filter.filterKey];

                    if (!filterState) {
                        throw Error('missing filter key ' + filter.filterKey);
                    }

                    if (filterState.set.size === 0) {
                        continue;
                    }

                    if (!filter.filterFn(row, filterState.set)) {
                        hidden = true;

                        break;
                    }
                }

                const visibleIndex = rowsFilteredVisibleCount;

                if (!hidden) {
                    rowsFilteredVisibleCount++;
                }

                return {
                    row,
                    visibleIndex,
                    hidden,
                };
            });

            return {
                rowsFiltered,
                rowsFilteredVisibleCount,
            };
        }, [isFilterDisabled, rows, state.filterStates]);

        const rowsFilteredAndSorted = React.useMemo(() => {
            const rowsSorted = rowsFiltered.slice();

            const sortAscendingFn =
                state.sortColumnIndex !== null
                    ? columns[state.sortColumnIndex][0].sortAscendingFn
                    : columnSelectorState.sortColumnIndex !== null
                      ? columnSelectorState.columnOptionObject[columnSelectorState.sortColumnIndex]?.[0]
                            ?.sortAscendingFn
                      : null;

            const reverseSort =
                state.sortColumnIndex !== null
                    ? state.sortDirection !== 'asc'
                    : columnSelectorState.sortDirection !== 'asc';
            const sortDirection = reverseSort ? -1 : 1;

            rowsSorted.sort((a, b) => (sortAscendingFn ? sortAscendingFn(a.row, b.row) * sortDirection : 0));

            return rowsSorted;
        }, [
            rowsFiltered,
            state.sortColumnIndex,
            state.sortDirection,
            columnSelectorState.sortColumnIndex,
            columnSelectorState.columnOptionObject,
            columnSelectorState.sortDirection,
        ]);

        const resetAll = () => {
            setLoading(true);

            setState((state) => {
                return {
                    ...state,
                    filterStates: defaultFilterState.filterStates,
                    sortColumnIndex: defaultFilterState.sortColumnIndex,
                    sortDirection: defaultFilterState.sortDirection,
                };
            });

            setColumnSelectorState(defaultColumnSelectorState);
        };

        const resetFilter = () => {
            setState((state) => {
                return {
                    ...state,
                    filterStates: defaultFilterState.filterStates,
                };
            });
        };

        const updateFilterState = (filterKey: FilterKey, value: string[]) => {
            setState((state) => {
                return {
                    ...state,
                    filterStates: {
                        ...state.filterStates,
                        [filterKey]: {
                            array: value,
                            set: new Set(value),
                        },
                    },
                };
            });
        };

        const updateSortIndex = React.useCallback((sortColumnIndex: number, type: 'state' | 'columnState') => {
            if (type === 'state') {
                setState((previous) => {
                    if (sortColumnIndex === previous.sortColumnIndex) {
                        return {
                            ...previous,
                            sortDirection: previous.sortDirection === 'asc' ? 'desc' : 'asc',
                        };
                    }

                    return {
                        ...previous,
                        sortColumnIndex,
                        sortDirection: 'asc',
                    };
                });

                setColumnSelectorState((previous) => {
                    return { ...previous, sortColumnIndex: null };
                });
            } else {
                setColumnSelectorState((previous) => {
                    if (sortColumnIndex === previous.sortColumnIndex) {
                        return {
                            ...previous,
                            sortDirection: previous.sortDirection === 'asc' ? 'desc' : 'asc',
                        };
                    }

                    return {
                        ...previous,
                        sortColumnIndex,
                        sortDirection: 'asc',
                    };
                });

                setState((previous) => {
                    return { ...previous, sortColumnIndex: null };
                });
            }
        }, []);

        const updateColumnSelectorState = (value: string[]) => {
            setColumnSelectorState((state) => {
                return {
                    ...state,
                    columnState: new Set(value.sort(localeCompare)),
                    sortColumnIndex: null,
                };
            });

            // reset the sort column index on changing column selector to prevent non viewable columns from being sorted
            setState((state) => ({ ...state, sortColumnIndex: 0 }));
        };

        const reportType = React.useMemo(() => getReportType(reportSet), [reportSet]);
        const reportIsDemosPlusPodcastAu = reportType === 'demosPlusPodcastAu';
        const shouldShowLoading = isLoading && reportIsDemosPlusPodcastAu && !isNarrow;

        React.useEffect(() => {
            setColumnSelectorState(() => {
                const options = getColumnOptionsData(rows, reportType);

                return {
                    ...getColumnOptionsData(rows, reportType),
                    columnState: new Set(options.columnOptionString.slice(0, 3)),
                    sortColumnIndex: null,
                    sortDirection: 'asc',
                };
            });

            const timeout = setTimeout(() => {
                if (rows.length > 0) {
                    setLoading(false);
                }
            }, 400); // Waiting for the characterstics filter to load (to avoid flickering)
            // Only for demosPlusPodcastAu reports on period change

            return () => {
                clearTimeout(timeout);
            };
        }, [reportSet, reportType, rows]);

        React.useEffect(() => {
            setState((state) => ({
                ...state,
                ...getFiltersState(rows),
            }));
        }, [rows]);

        const { recordOverlayState, showRecordOverlayForId, hideRecordOverlay } = useRecordOverlay(rows, getRowId);

        const intl = useIntl();
        const locale = useLocale();

        const allLabel = intl.formatMessage({
            defaultMessage: 'All',
            description: 'Option to filter everything in a list',
        });

        const clearFilterLabel = intl.formatMessage({
            defaultMessage: 'Clear filter',
            description: 'Button to clear existing filters',
        });

        const periodLabel = intl.formatMessage({
            defaultMessage: 'Period',
            description: 'Label for selecting period',
        });

        const regionLabel = intl.formatMessage({
            defaultMessage: 'Region',
            description: 'Label for selecting regions',
        });

        const rankerLabel = intl.formatMessage({
            defaultMessage: 'Ranker',
            description: 'Label for selecting rankers',
        });

        const selectCharacteristicLabel = intl.formatMessage({
            defaultMessage: 'Select one or more characteristics',
            description: 'Select Characteristic Label',
        });

        const reportSetTitle = React.useMemo(() => {
            return getReportSetTitle(reportSet);
        }, [reportSet]);

        const pdfUrl = React.useMemo(() => {
            return getRankerPdfUrl({
                regionId: reportSet.regionId,
                slug: reportSet.slug,
                year: period.year,
                month: period.month,
                locale,
                accessKey: accessKey ?? undefined,
            });
        }, [accessKey, locale, period.month, period.year, reportSet.regionId, reportSet.slug]);

        const hasFiltersOrColumnSelector = React.useMemo(() => filters.length > 0 || selector, []);

        const buildPdfFilterBody = React.useCallback(
            (filterHidden?: boolean) => {
                const filterBody = {} as Record<FilterKey | 'columns', string[]>;

                for (const filter of filters) {
                    const filterState = state.filterStates[filter.filterKey];

                    if (!filterState || filterState.set.size === 0) {
                        continue;
                    }
                    filterBody[filter.filterKey] = Array.from(filterState.set);
                }

                if (filterHidden) {
                    // We can show all the columns in the export when user is on a phone or a small tablet because filters are hidden
                    filterBody['columns'] = Array.from(columnSelectorState.columnOptionString);
                } else if (columnSelectorState.columnState.size > 0) {
                    filterBody['columns'] = Array.from(columnSelectorState.columnState);
                }

                return JSON.stringify({
                    filter: filterBody,
                    regionId: reportSet.regionId,
                    slug: reportSet.slug,
                    year: `${period.year}`,
                    month: `${period.month}`,
                    locale,
                    accessKey: accessKey ?? undefined,
                });
            },
            [
                columnSelectorState.columnState,
                columnSelectorState.columnOptionString,
                reportSet.regionId,
                reportSet.slug,
                period.year,
                period.month,
                locale,
                accessKey,
                state.filterStates,
            ],
        );

        const fetchPdf = React.useCallback(
            async (filterHidden?: boolean) => {
                await fetch(pdfUrl, {
                    method: 'POST',
                    body: buildPdfFilterBody(filterHidden),
                })
                    .then(async (response) => {
                        if (response.ok) {
                            return response.blob();
                        }
                        throw new Error('Bad response');
                    })
                    .then((pdfBuffer) => {
                        const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        const title = regionTitles[reportSet.regionId];
                        const reportSetTitle = getReportSetTitle(reportSet);
                        link.download = `${title} - ${reportSetTitle} ${period.year}-${period.month}.pdf`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                    })
                    .catch((_error) => {
                        console.error('Error fetching PDF');
                    });
            },
            [buildPdfFilterBody, pdfUrl, period.month, period.year, reportSet],
        );

        const getColumnHeader = React.useCallback(
            (column: TableColumn<Ranker['rows'][number]>, isSortingColumn: boolean, index: number): React.ReactNode => {
                if (index === 0 && column.tooltip) {
                    return (
                        <TritonTooltip title={intl.formatMessage(column.tooltip)}>
                            <TableSortLabel
                                active={isSortingColumn}
                                direction={isSortingColumn ? columnSelectorState.sortDirection : undefined}
                                onClick={() => {
                                    updateSortIndex(0, 'columnState');
                                }}
                            >
                                <FormattedMessage {...column.headerMessageDescriptor} />
                                <InfoOutlined />
                            </TableSortLabel>
                        </TritonTooltip>
                    );
                }

                return (
                    <TableSortLabel
                        active={isSortingColumn}
                        direction={isSortingColumn ? columnSelectorState.sortDirection : undefined}
                        onClick={() => {
                            updateSortIndex(index, 'columnState');
                        }}
                    >
                        <FormattedMessage {...column.headerMessageDescriptor} />
                    </TableSortLabel>
                );
            },
            [columnSelectorState.sortDirection, intl, updateSortIndex],
        );

        return (
            <>
                <Grid container spacing={1} item xs={12} md={hasFiltersOrColumnSelector ? 6 : 12}>
                    <Grid item xs={12} md={4} zeroMinWidth>
                        <ReportFormControl fullWidth>
                            <ReportInputLabel>{regionLabel}</ReportInputLabel>
                            <ReportSelect
                                value={reportSet.regionId}
                                label={regionLabel}
                                onChange={(event) => {
                                    const newRegionId = event.target.value;

                                    void router.push(
                                        getRankerUrl({
                                            regionId: newRegionId,
                                        }),
                                    );
                                }}
                            >
                                {regionIds.map((regionId) => {
                                    return (
                                        <MenuItem key={regionId} value={regionId}>
                                            <span
                                                css={{
                                                    marginRight: 5,
                                                    lineHeight: 1,
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                <Flag
                                                    regionId={regionId}
                                                    title={regionLabels[regionId]}
                                                    width={4}
                                                    height={3}
                                                    css={{ width: 24, height: 'auto' }}
                                                />
                                            </span>
                                            {regionTitles[regionId]}
                                        </MenuItem>
                                    );
                                })}
                            </ReportSelect>
                        </ReportFormControl>
                    </Grid>
                    <Grid item xs={6} md={4} zeroMinWidth>
                        <ReportFormControl fullWidth>
                            <ReportInputLabel>{rankerLabel}</ReportInputLabel>
                            <ReportSelect
                                value={reportSet.slug}
                                label={rankerLabel}
                                onChange={(event) => {
                                    const reportSetOption = reportSetLinks.find(
                                        ({ reportSet }) => reportSet.slug === event.target.value,
                                    );

                                    if (!reportSetOption) {
                                        throw Error('reportSetOption not found');
                                    }

                                    resetAll();

                                    const url = getRankerUrl({
                                        regionId: reportSetOption.reportSet.regionId,
                                        slug: reportSetOption.reportSet.slug,
                                        period: reportSetOption.hasReportForCurrentPeriod ? period : undefined,
                                    });

                                    void router.push(url);
                                }}
                            >
                                {reportSetLinks.map(({ reportSet }) => (
                                    <MenuItem
                                        key={reportSet.slug}
                                        value={reportSet.slug}
                                        css={{
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {getReportSetTitle(reportSet)}
                                    </MenuItem>
                                ))}
                            </ReportSelect>
                        </ReportFormControl>
                    </Grid>
                    <Grid item xs={6} md={4} zeroMinWidth>
                        <ReportFormControl fullWidth>
                            <ReportInputLabel>{periodLabel}</ReportInputLabel>
                            <ReportSelect
                                value={getReportPeriodId(period)}
                                label={periodLabel}
                                renderValue={() => (
                                    <div css={{ lineHeight: 1, marginTop: 4 }}>
                                        <div css={{ fontSize: '0.9em' }}>{formatReportPeriodLabel(period, locale)}</div>
                                        <div
                                            css={{
                                                marginTop: '0.2em',
                                                fontSize: '0.7em',
                                                color: theme.tritonColors.gray,
                                            }}
                                        >
                                            <FormattedDate
                                                value={getDateFromDateString(periodStartDateString)}
                                                month="short"
                                                day="numeric"
                                            />
                                            {` - `}
                                            <FormattedDate
                                                value={getDateFromDateString(periodEndDateString)}
                                                month="short"
                                                day="numeric"
                                            />
                                        </div>
                                    </div>
                                )}
                                onChange={(event) => {
                                    const newPeriod = reportPeriods.find(
                                        (period) => getReportPeriodId(period) === event.target.value,
                                    );

                                    if (!newPeriod) {
                                        throw Error('ReportPeriod not found');
                                    }

                                    resetAll();

                                    void router.push(
                                        getRankerUrl({
                                            regionId: reportSet.regionId,
                                            slug: reportSet.slug,
                                            period: newPeriod,
                                        }),
                                    );
                                }}
                            >
                                {reportPeriods.map((period) => {
                                    const periodId = getReportPeriodId(period);

                                    return (
                                        <MenuItem key={periodId} value={periodId}>
                                            {formatReportPeriodLabel(period, locale)}
                                        </MenuItem>
                                    );
                                })}
                            </ReportSelect>
                        </ReportFormControl>
                    </Grid>
                </Grid>
                {shouldShowLoading ? (
                    <Grid
                        flexGrow={1}
                        sx={{
                            display: 'flex',
                            minHeight: 50,
                        }}
                        style={{ maxHeight: '150px', justifyContent: 'center' }}
                    >
                        <LoadingButton loading loadingPosition="center" size="large" />
                    </Grid>
                ) : (
                    <>
                        <Grid container item xs={12}>
                            <Grid
                                container
                                spacing={{ sm: 0.5, md: 1 }}
                                justifyContent={hasFiltersOrColumnSelector ? '' : 'flex-end'}
                            >
                                {!isFilterDisabled && hasFiltersOrColumnSelector ? (
                                    <Grid item xs={12} md={9}>
                                        <Grid container spacing={1} justifyContent="" alignItems="center">
                                            <Grid item xs="auto">
                                                <Typography variant="subtitle2" component="h1">
                                                    <FormattedMessage
                                                        defaultMessage="Filters"
                                                        description="Label for report table filters"
                                                    />
                                                    :
                                                </Typography>
                                            </Grid>
                                            {filters.map((filter) => {
                                                const filterOptions = state.filterOptions[filter.filterKey];
                                                const filterState = state.filterStates[filter.filterKey];

                                                if (!filterOptions || !filterState) {
                                                    throw Error('missing filterOptions/filterState');
                                                }

                                                const filterEnabled = filterState.array.length > 0;

                                                return (
                                                    <Grid key={filter.filterKey} item xs zeroMinWidth>
                                                        <ReportFormControl fullWidth selected={filterEnabled}>
                                                            <ReportInputLabel shrink>
                                                                <FormattedMessage {...filter.messageDescriptorHeader} />
                                                            </ReportInputLabel>
                                                            <ReportSelectSecondary
                                                                value={filterState.array}
                                                                label={
                                                                    <FormattedMessage
                                                                        {...filter.messageDescriptorHeader}
                                                                    />
                                                                }
                                                                multiple
                                                                displayEmpty
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return allLabel;
                                                                    }

                                                                    if (selected.length === 1) {
                                                                        return Array.from(selected)[0];
                                                                    }

                                                                    return intl.formatMessage(
                                                                        filter.messageDescriptorMultipleValues,
                                                                        {
                                                                            count: selected.length,
                                                                        },
                                                                    );
                                                                }}
                                                                endAdornment={
                                                                    filterEnabled ? (
                                                                        <TritonTooltip title={clearFilterLabel}>
                                                                            <IconButton
                                                                                color="inherit"
                                                                                css={{
                                                                                    position: 'absolute',
                                                                                    right: 20,
                                                                                }}
                                                                                onClick={() => {
                                                                                    updateFilterState(
                                                                                        filter.filterKey,
                                                                                        [],
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </TritonTooltip>
                                                                    ) : null
                                                                }
                                                                onChange={(event) => {
                                                                    if (typeof event.target.value === 'string') {
                                                                        throw Error(
                                                                            'ReportSelect onChange passed string',
                                                                        );
                                                                    }

                                                                    updateFilterState(
                                                                        filter.filterKey,
                                                                        event.target.value,
                                                                    );
                                                                }}
                                                            >
                                                                {filterOptions.map((option) => (
                                                                    <MenuItem key={option} value={option} dense>
                                                                        <Checkbox
                                                                            sx={{
                                                                                paddingLeft: 1,
                                                                                paddingTop: 0.5,
                                                                                paddingRight: 1,
                                                                                paddingBottom: 0.5,
                                                                            }}
                                                                            checked={filterState.set.has(option)}
                                                                        />
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </ReportSelectSecondary>
                                                        </ReportFormControl>
                                                    </Grid>
                                                );
                                            })}
                                            {columnSelectorState.columnOptionString.length && selector && !isNarrow ? (
                                                <Grid key={selector.key} item xs zeroMinWidth>
                                                    <ReportFormControl
                                                        fullWidth
                                                        selected={columnSelectorState.columnState.size > 0}
                                                    >
                                                        <ReportInputLabel shrink>
                                                            <FormattedMessage {...selector.messageDescriptorHeader} />
                                                        </ReportInputLabel>
                                                        <ReportSelectSecondary
                                                            value={Array.from(columnSelectorState.columnState)}
                                                            label={
                                                                <FormattedMessage
                                                                    {...selector.messageDescriptorHeader}
                                                                />
                                                            }
                                                            multiple
                                                            displayEmpty
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return selectCharacteristicLabel;
                                                                }

                                                                if (selected.length === 1) {
                                                                    return selected[0];
                                                                }

                                                                return intl.formatMessage(
                                                                    selector.messageDescriptorMultipleValues,
                                                                    {
                                                                        count: selected.length,
                                                                    },
                                                                );
                                                            }}
                                                            endAdornment={
                                                                columnSelectorState.columnState.size ? (
                                                                    <TritonTooltip title={clearFilterLabel}>
                                                                        <IconButton
                                                                            color="inherit"
                                                                            css={{ position: 'absolute', right: 20 }}
                                                                            onClick={() => {
                                                                                setColumnSelectorState((previous) => ({
                                                                                    ...previous,
                                                                                    columnState: new Set(),
                                                                                    sortColumnIndex: null,
                                                                                }));

                                                                                setState((previous) => ({
                                                                                    ...previous,
                                                                                    sortColumnIndex: 0,
                                                                                }));
                                                                            }}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    </TritonTooltip>
                                                                ) : null
                                                            }
                                                            onChange={(event) => {
                                                                if (typeof event.target.value === 'string') {
                                                                    throw Error('Select onChange passed string');
                                                                }

                                                                updateColumnSelectorState(event.target.value);
                                                            }}
                                                        >
                                                            {columnSelectorState.columnOptionString.map((option) => (
                                                                <MenuItem key={option} value={option} dense>
                                                                    <Checkbox
                                                                        sx={{
                                                                            paddingLeft: 1,
                                                                            paddingTop: 0.5,
                                                                            paddingRight: 1,
                                                                            paddingBottom: 0.5,
                                                                        }}
                                                                        checked={columnSelectorState.columnState.has(
                                                                            option,
                                                                        )}
                                                                    />
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </ReportSelectSecondary>
                                                    </ReportFormControl>
                                                </Grid>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    </Grid>
                                ) : null}
                                <Grid item xs md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                        <Grid item width="100%">
                                            <ReportDownloadButton
                                                sx={{ height: '56px', width: '100%' }}
                                                LinkComponent={Link}
                                                onClick={() => {
                                                    void fetchPdf();
                                                }}
                                            >
                                                <FileDownloadIcon sx={{ mr: 1 }} />
                                                <FormattedMessage
                                                    defaultMessage="Export as PDF"
                                                    description="Export PDF button in table header"
                                                />
                                            </ReportDownloadButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            flexGrow={1}
                            sx={{
                                display: 'flex',
                                minHeight: 320,
                                height: 0, // fixes table height to be 100% in flex container
                            }}
                            style={{ width: '100%' }}
                        >
                            <TableContainer
                                sx={{ background: theme.palette.grey[200] }}
                                style={{ maxWidth: '1152px', overflowX: 'auto' }}
                            >
                                <TritonTable stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(([column, columnConfig], index) => {
                                                const isSortingColumn =
                                                    index === state.sortColumnIndex &&
                                                    !columnSelectorState.sortColumnIndex;

                                                return (
                                                    <TableCell
                                                        key={index}
                                                        align={column.align}
                                                        style={{
                                                            display:
                                                                columnConfig.hideWhenNarrow && isNarrow
                                                                    ? 'none'
                                                                    : undefined,
                                                            width: columnConfig.width,
                                                        }}
                                                        sortDirection={isSortingColumn ? state.sortDirection : false}
                                                    >
                                                        <TableSortLabel
                                                            active={isSortingColumn}
                                                            direction={
                                                                isSortingColumn ? state.sortDirection : undefined
                                                            }
                                                            onClick={() => {
                                                                updateSortIndex(index, 'state');
                                                            }}
                                                        >
                                                            <FormattedMessage {...column.headerMessageDescriptor} />
                                                        </TableSortLabel>
                                                    </TableCell>
                                                );
                                            })}
                                            {columnSelectorState.columnState.size > 0 &&
                                                columnSelectorState.columnOptionObject.length > 0 &&
                                                Array.from(columnSelectorState.columnState).map((columnKey) => {
                                                    const column = columnSelectorState.columnOptionObject.find(
                                                        (e) =>
                                                            intl.formatMessage(e[0].headerMessageDescriptor) ===
                                                            columnKey,
                                                    );
                                                    const columnConfig = column?.[1];

                                                    const sortingIndex =
                                                        columnSelectorState.columnOptionString.indexOf(columnKey);

                                                    const isSortingColumn =
                                                        sortingIndex === columnSelectorState.sortColumnIndex;

                                                    if (column && columnConfig) {
                                                        return (
                                                            <TableCell
                                                                key={`selectable_col_${sortingIndex}`}
                                                                align={column[0].align}
                                                                style={{
                                                                    display:
                                                                        columnConfig.hideWhenNarrow && isNarrow
                                                                            ? 'none'
                                                                            : undefined,
                                                                    width: columnConfig.width,
                                                                }}
                                                                sortDirection={
                                                                    isSortingColumn
                                                                        ? columnSelectorState.sortDirection
                                                                        : false
                                                                }
                                                            >
                                                                {getColumnHeader(
                                                                    column[0],
                                                                    isSortingColumn,
                                                                    sortingIndex,
                                                                )}
                                                            </TableCell>
                                                        );
                                                    }

                                                    return null;
                                                })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rankerJsonResult.type === 'loading' ? (
                                            <TableStatusRow>
                                                <CircularProgress />
                                            </TableStatusRow>
                                        ) : rankerJsonResult.type === 'notFound' ? (
                                            <TableStatusRow>
                                                <TableDataNotFound />
                                            </TableStatusRow>
                                        ) : rankerJsonResult.type === 'error' ? (
                                            <TableStatusRow>
                                                <TableDataError />
                                            </TableStatusRow>
                                        ) : rowsFilteredVisibleCount === 0 ? (
                                            <TableStatusRow>
                                                <TableDataEmpty
                                                    onClearFilters={() => {
                                                        resetFilter();
                                                    }}
                                                />
                                            </TableStatusRow>
                                        ) : (
                                            rowsFilteredAndSorted.map(({ row, visibleIndex, hidden }) => {
                                                const rowId = getRowId(row);

                                                return (
                                                    <TableRow
                                                        key={rowId}
                                                        css={
                                                            visibleIndex % 2
                                                                ? tritonTableRecordRow
                                                                : tritonTableRecordRowAlternate
                                                        }
                                                        style={hidden ? { display: 'none' } : undefined}
                                                        tabIndex={-1}
                                                        onClick={() => {
                                                            showRecordOverlayForId(rowId);
                                                        }}
                                                    >
                                                        {columns.map(([column, columnConfig], index) => {
                                                            return (
                                                                <TableCell
                                                                    key={index}
                                                                    align={column.align}
                                                                    style={{
                                                                        display:
                                                                            columnConfig.hideWhenNarrow && isNarrow
                                                                                ? 'none'
                                                                                : undefined,
                                                                        width: columnConfig.width,
                                                                    }}
                                                                >
                                                                    {column.renderCell(row)}
                                                                </TableCell>
                                                            );
                                                        })}
                                                        {columnSelectorState.columnState.size > 0 &&
                                                            columnSelectorState.columnOptionObject.length > 0 &&
                                                            Array.from(columnSelectorState.columnState).map(
                                                                (columnKey, index) => {
                                                                    const column =
                                                                        columnSelectorState.columnOptionObject.find(
                                                                            (e) =>
                                                                                intl.formatMessage(
                                                                                    e[0].headerMessageDescriptor,
                                                                                ) === columnKey,
                                                                        );
                                                                    const columnConfig = column?.[1];

                                                                    if (column && columnConfig) {
                                                                        return (
                                                                            <TableCell
                                                                                key={index}
                                                                                align={column[0].align}
                                                                                style={{
                                                                                    display:
                                                                                        columnConfig.hideWhenNarrow &&
                                                                                        isNarrow
                                                                                            ? 'none'
                                                                                            : undefined,
                                                                                    width: columnConfig.width,
                                                                                }}
                                                                            >
                                                                                {column[0].renderCell(row)}
                                                                            </TableCell>
                                                                        );
                                                                    }

                                                                    return null;
                                                                },
                                                            )}
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </TritonTable>
                            </TableContainer>
                        </Grid>
                        <Grid container item justifyContent="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <Grid item xs={12}>
                                <TritonDivider />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button href="#disclaimer" sx={{ width: '100%' }}>
                                            <SubjectIcon fontSize="small" />
                                            <FormattedMessage
                                                defaultMessage="Disclaimers"
                                                description="Link to the disclaimers section"
                                            />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            LinkComponent={Link}
                                            sx={{ width: '100%' }}
                                            onClick={() => {
                                                void fetchPdf(isNarrow || isFilterDisabled);
                                            }}
                                        >
                                            <FileDownloadIcon fontSize="small" />
                                            <FormattedMessage
                                                defaultMessage="Export as PDF"
                                                description="Export PDF button in mobile footer"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Dialog
                    open={recordOverlayState.type === 'visible'}
                    maxWidth="md"
                    fullWidth
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: 0,
                        },
                    }}
                    onClose={() => {
                        hideRecordOverlay();
                    }}
                >
                    {recordOverlayState.type !== 'empty' && (
                        <ReportOverlay
                            reportTitle={regionTitles[reportSet.regionId]}
                            reportSetTitle={reportSetTitle}
                            periodLabel={formatReportPeriodLabel(period, locale)}
                            onClose={() => {
                                hideRecordOverlay();
                            }}
                        >
                            <Overlay
                                row={recordOverlayState.row}
                                onFilterApply={
                                    !isFilterDisabled
                                        ? (filterKey, filterValues) => {
                                              updateFilterState(filterKey, filterValues);

                                              hideRecordOverlay();
                                          }
                                        : null
                                }
                            />
                        </ReportOverlay>
                    )}
                </Dialog>
            </>
        );
    };

    const getPdfData = (report: Ranker, Locale: Locale, columnSelection?: string[]): ReportTablePdfData => {
        const intl = createIntl({
            locale: Locale,
            messages: messagesByLocale[Locale],
        });
        const filteredColumns = pdfColumns.slice();

        if (report.type === 'demosPlusPodcastAu') {
            const columnOptions = getColumnOptionsData(report.rows, report.type, true);

            if (columnOptions.columnOptionObjectPdf) {
                const foundColumns = columnOptions.columnOptionObjectPdf.filter((e) =>
                    columnSelection?.includes(intl.formatMessage(e[0].headerMessageDescriptor)),
                );
                filteredColumns.push(...foundColumns);
            }
        }

        const headers = filteredColumns.map(([column]) => {
            return intl.formatMessage(column.headerMessageDescriptor);
        });

        const rows = report.rows.map((row) => {
            return filteredColumns.map(([column]) => {
                // createIntl creates a slightly different IntlShape, but it's not exported
                return column.renderCellPdf(row, intl as IntlShape);
            });
        });

        return {
            headers,
            rows,
        };
    };

    return {
        getPdfData,
        Component: ReportTable,
    };
}

// we want to call createReportTable with a Row generic, but let the FilterKey be inferred.
// TypeScript doesn't support this, so we use two functions.
// the outer function sets the Row generic and the inner function uses inference.
// createReportTablePartial<{ rowExample: number }>()(options)
function createReportTablePartial<Report extends RankerApi>() {
    return function wrapper<FilterKey extends string = never>(options: CreateReportTableConfig<Report, FilterKey>) {
        return createReportTable(options);
    };
}

// use the location hash as the source of truth to indicate whether the overlay is shown,
// taking into account the hash may not match a record.
function useRecordOverlay<Row>(rows: Row[], getRowId: (row: Row) => string) {
    // the current ID extracted from the hash
    const [currentHashId, setCurrentHashId] = React.useState('');

    React.useEffect(() => {
        const onHashChanged = () => {
            const currentHashId = window.decodeURIComponent(window.location.hash.substring(1));

            setCurrentHashId(currentHashId);
        };

        // calculate on initial render
        onHashChanged();

        window.addEventListener('hashchange', onHashChanged);

        return () => {
            window.removeEventListener('hashchange', onHashChanged);
        };
    }, []);

    // we want to store the record while hidden so we can still show it during the fade out animation
    const [overlayState, setOverlayState] = React.useState<
        { type: 'empty' } | { type: 'visible'; row: Row } | { type: 'hidden'; row: Row }
    >({ type: 'empty' });

    React.useEffect(() => {
        const row = currentHashId ? rows.find((row) => getRowId(row) === currentHashId) : undefined;

        if (row) {
            setOverlayState({
                type: 'visible',
                row,
            });
        } else {
            setOverlayState((state) => {
                switch (state.type) {
                    case 'empty':
                    case 'hidden': {
                        return state;
                    }

                    case 'visible': {
                        return {
                            type: 'hidden',
                            row: state.row,
                        };
                    }
                }
            });
        }
    }, [currentHashId, rows, getRowId]);

    const router = useRouter();

    return {
        recordOverlayState: overlayState,
        showRecordOverlayForId: (recordId: string) => {
            window.location.hash = `#${recordId}`;
        },
        hideRecordOverlay: () => {
            // clear the hash to trigger the hashchange event
            window.location.hash = '';

            // clearing the hash leaves an empty # in the URL.
            // to remove this, we also strip the hash out of the URL and redirect the page to that URL.
            const pathWithoutHash = router.asPath.split('#')[0];

            if (!pathWithoutHash) {
                return;
            }

            // use shallow true to avoid getServerSideProps being run again.
            // we know data does not need to be refetched.
            void router.replace(pathWithoutHash, undefined, { shallow: true });
        },
    };
}

export const TableDataEmpty = ({ onClearFilters }: { onClearFilters: () => void }) => {
    return (
        <Stack spacing={2}>
            <FormatListNumberedIcon
                css={{
                    fontSize: 100,
                    color: grey[300],
                    margin: '0 auto',
                }}
            />
            <Typography variant="h5" color={grey[700]}>
                <FormattedMessage defaultMessage="No data to display" description="Table missing data heading" />
            </Typography>
            <Typography color={grey[500]}>
                <FormattedMessage defaultMessage="Try adjusting or" description="Table missing data description" />{' '}
                <Link component="button" variant="body1" sx={{ verticalAlign: 'bottom' }} onClick={onClearFilters}>
                    <FormattedMessage
                        defaultMessage="clearing your filters"
                        description="Table missing data call to action to clear filters"
                    />
                </Link>
            </Typography>
        </Stack>
    );
};

const TableDataError = () => {
    const router = useRouter();

    return (
        <Stack spacing={2}>
            <ErrorIcon
                css={{
                    fontSize: 100,
                    color: grey[300],
                    margin: '0 auto',
                }}
            />
            <Typography variant="h5" color={grey[700]}>
                <FormattedMessage defaultMessage="Error retrieving report" description="Table data error heading" />
            </Typography>
            <Typography color={grey[500]}>
                <Link
                    component="button"
                    variant="body1"
                    sx={{ verticalAlign: 'bottom' }}
                    onClick={() => {
                        router.reload();
                    }}
                >
                    <FormattedMessage defaultMessage="Retry" description="Table data error call to action to retry" />
                </Link>
            </Typography>
        </Stack>
    );
};

const TableDataNotFound = () => {
    return (
        <Stack spacing={2}>
            <ErrorIcon
                css={{
                    fontSize: 100,
                    color: grey[300],
                    margin: '0 auto',
                }}
            />
            <Typography variant="h5" color={grey[700]}>
                <FormattedMessage defaultMessage="Report not found" description="Table data not found heading" />
            </Typography>
            <Typography color={grey[500]}>
                <FormattedMessage
                    defaultMessage="The requested report could not be loaded. Please check the URL is valid."
                    description="Table data not found description"
                />
            </Typography>
        </Stack>
    );
};

const TableStatusRow = ({ children }: React.PropsWithChildren<unknown>) => (
    <TableRow>
        <TableCell colSpan={99}>
            <Box css={{ paddingTop: 20, paddingBottom: 20 }} textAlign="center">
                {children}
            </Box>
        </TableCell>
    </TableRow>
);
